import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'
import {
  colors,
  spacer,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { ExternalLinkBtn } from '@dqp/common/components/Link/Link'
import ribbons from '#images/howitworks/ribbons.svg'
import routes from '#globals/routes'
import { reactRevealDuration } from '#globals/constants'

const styles = {
  container: css`
    position: relative;
    ${breakpoints({
      paddingTop: [80, 80, 160, 140, 100],
      paddingBottom: [80, 80, 145, 300, 300],
    })};
  `,
  title: css`
    ${spacer.mrB20}
  `,
  text: css`
    ${spacer.mrB30}
  `,
  ribbons: css`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 110%;
    height: 110%;
    transform: translate(-50%, -58%);
  `,
  outer: css`
    z-index: 1;
    position: relative;
    overflow: hidden;
    /* overflow: hidden;
    background-image: url(${ribbons});
    background-repeat: no-repeat;
    background-position: 50% 40%; */
    
  `,
}
function ChoosePlan() {
  return (
    <div
      className='outer d-flex flex-column align-items-stretch'
      css={styles.outer}
    >
      <section
        css={styles.container}
        style={{ zIndex: 3, position: 'relative' }}
      >
        <div className='container'>
          <div className='row justify-content-center text-center'>
            <Fade clear cascade duration={reactRevealDuration}>
              <div className='col-lg-8'>
                <Title
                  as='h2'
                  size='xxxxxLarge'
                  weight='bold'
                  color={colors.black}
                  css={styles.title}
                >
                  Choose a plan later
                </Title>
                <Text
                  color={colors.charcoal}
                  size='large'
                  css={styles.text}
                >
                  Try out Daily Quest Plus for free and then choose
                  the plan that’s best for you.
                </Text>
                <ExternalLinkBtn
                  variant='blue'
                  size='large'
                  inline
                  to={routes.signup}
                >
                  SIGN UP FREE
                </ExternalLinkBtn>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <img
        alt='ribbons'
        css={styles.ribbons}
        src={ribbons}
        className='d-none d-md-block'
        style={{ zIndex: 2 }}
      />
    </div>
  )
}

export default ChoosePlan
