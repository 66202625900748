import React from 'react'
import Fade from 'react-reveal/Fade'
import PricingCard from './PricingCard'
import pizzaSlice from '#images/pricing/pizzaSlice.svg'
import cappuchino from '#images/pricing/cappuchino.svg'
import espresso from '#images/pricing/espresso.svg'
import {
  reactRevealDuration,
  reactRevealDistance,
  staggerDelay,
} from '#globals/constants'

const pricingData = [
  {
    name: `FREE`,
    price: `0`,
    rate: `per subject, per month`,
    description: `Try it out and get some questions and tutorials for free!`,
    ctaImg: pizzaSlice,
    ctaText: `Enjoy a taste of one or more subjects!`,
  },
  {
    name: `MONTHLY`,
    price: `15`,
    rate: `per subject, per month`,
    description: `Get two new questions and tutorials per subject each day.`,
    ctaImg: cappuchino,
    ctaText: `That’s just one grande cappuccino per week!`,
  },
  {
    name: `ANNUALLY`,
    price: `8.25`,
    rate: (
      <>
        per subject, per month <br />
        (billed annually at &pound;99)
      </>
    ),
    description: `Get full access to all 770 questions and tutorials for each subject.`,
    ctaImg: espresso,
    ctaText: `That’s just one espresso per week!`,
    savings: 81,
  },
]
function PricingCards() {
  return (
    <div className='container relative' style={{ zIndex: 3 }}>
      <div className='row'>
        {pricingData.map((data, i) => (
          <Fade
            duration={reactRevealDuration}
            bottom
            distance={reactRevealDistance}
            delay={i * staggerDelay}
            key={data.name}
          >
            <div
              className={`col-lg-4 d-flex align-items-stretch mb-lg-0 ${
                i !== pricingData.length - 1 ? 'mb-4' : ''
              }`}
            >
              <PricingCard {...data} />
            </div>
          </Fade>
        ))}
      </div>
    </div>
  )
}

export default PricingCards
