import React from 'react'
import { css } from '@emotion/core'
import {
  colors,
  spacer,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { ExternalLinkBtn } from '@dqp/common/components/Link/Link'
import Image from '#components/Image'
import routes from '#globals/routes'

const styles = {
  container: css`
    ${breakpoints({
      paddingTop: [40, 40, 160, 140, 140],
      paddingBottom: [80, 80, 145, 145, 145],
    })};
  `,
  title: css`
    ${spacer.mrB20}
  `,
  text: css`
    ${spacer.mrB30}
  `,
  clip: css`
    ${breakpoints({
      right: [null, null, -100, -100, -100],
      top: [null, null, 50, 60, 60],
    })};
  `,
  rocket: css`
    ${breakpoints({
      left: [null, null, -303, -360, -280],
      top: [null, null, 40, 60, 60],
    })};
  `,
  scissors: css`
    ${breakpoints({
      right: [null, null, -200, -200, -200],
      bottom: [null, null, -135, -135, -135],
    })};
  `,
}

function Hero() {
  return (
    <div className='container relative' css={styles.container}>
      <div className='row justify-content-center text-center'>
        <div className='col-lg-8'>
          <Title
            as='h1'
            size='xxxxxLarge'
            weight='bold'
            color={colors.black}
            css={styles.title}
          >
            Our plans
          </Title>
          <Text color={colors.black} size='large' css={styles.text}>
            Sign up and choose your plan later.
          </Text>

          <ExternalLinkBtn
            variant='black'
            size='large'
            inline
            to={routes.signup}
          >
            TRY FOR FREE
          </ExternalLinkBtn>
        </div>
      </div>
      <Image
        css={styles.rocket}
        className='d-none d-md-block'
        alt='rocket'
        image='pricing/rocket'
        sizes={{
          lg: {
            width: 466,
          },
          md: {
            width: 380,
          },
        }}
      />
      <Image
        css={styles.clip}
        className='d-none d-md-block'
        alt='clip'
        image='pricing/clip'
        sizes={{
          lg: {
            width: 143,
          },
          md: {
            width: 130,
          },
        }}
      />
      <Image
        css={styles.scissors}
        className='d-none d-md-block'
        alt='scissors'
        image='pricing/scissors'
        sizes={{
          lg: {
            width: 443,
          },
          md: {
            width: 380,
          },
        }}
      />
    </div>
  )
}

export default Hero
