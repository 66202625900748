import React from 'react'
import Background from './Background'
import Hero from './Hero'
import PricingCards from './PricingCards'
import ChoosePlan from './ChoosePlan'

function Pricing() {
  return (
    <div className='relative'>
      <Background />
      <Hero />
      <PricingCards />
      <ChoosePlan />
    </div>
  )
}

export default Pricing
