import React from 'react'
import { css } from '@emotion/core'
import Wave from '@dqp/common/components/Wave/Wave'
import { colors, breakpoints } from '@dqp/common/styles/utilities'

const styles = {
  background: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    ${breakpoints({
      height: ['25%', '25%', '25%', '50%', '50%'],
    })};
    transform: rotateY(180deg);
    z-index: -1;
  `,
}
function Background() {
  return (
    <div css={styles.background}>
      <div
        css={css`
          height: 100%;
          background-color: ${colors.yellow};
        `}
      />
      <Wave
        css={css`
          transform: rotateY(180deg);
        `}
        bg={colors.yellow}
        fill={colors.white}
      />
    </div>
  )
}

export default Background
