import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import PublicLayout from '#components/PublicLayout'
import SEO from '#components/SEO'
import Pricing from '#containers/Pricing/Pricing'
import heroimage from '#images/hero/pricing_hero.png'

const PricingPage = () => (
  <PublicLayout headerColor={colors.yellow}>
    <SEO
      title='Pricing'
      meta={[{ name: 'theme-color', content: colors.yellow }]}
      image={heroimage}
    />
    <Pricing />
  </PublicLayout>
)

export default PricingPage
