import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import {
  colors,
  spacer,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'

const styles = {
  container: css`
    background: ${colors.white};
    border-radius: 8px;
    box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.38);
    flex: 1;
    padding: 18px;
    position: relative;
    overflow: hidden;
  `,

  planContainer: css`
    padding: 50px 0;
  `,
  description: css`
    padding: 0 20px;
    padding-bottom: 50px;
  `,
  ctaContainer: css`
    background-color: ${colors.greyLightest};
    border-radius: 8px;
    padding: 18px;
  `,
  ctaImg: css`
    ${spacer.mrR20};
    ${breakpoints({
      width: [40, 60],
    })}
  `,
  badge: css`
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: ${colors.black};
    top: 0;
    right: 0;
    transform: rotate(45deg) translateY(-80%);
    padding: 10px;
  `,
}

const PricingCard = props => {
  return (
    <div
      className='d-flex flex-column justify-content-between text-center'
      css={styles.container}
    >
      {' '}
      {props.savings && (
        <div
          css={styles.badge}
          className='d-flex align-items-end justify-content-center'
        >
          <Text size='medium'>
            SAVE <strong>&pound;{props.savings}</strong>
          </Text>
        </div>
      )}
      <div>
        <div
          css={styles.planContainer}
          className='d-flex flex-column align-items-stretch'
        >
          <Title
            as='h4'
            color={colors.black}
            weight='bold'
            size='xxLarge'
          >
            {props.name}
          </Title>
          <Title
            as='h2'
            color={colors.purple}
            weight='bold'
            size='xxxxxLarge'
          >
            &pound;{props.price}
          </Title>
          <Text
            as='span'
            color={colors.grey}
            weight='bold'
            size='small'
          >
            {props.rate}
          </Text>
        </div>

        <div css={styles.description}>
          <Text color={colors.charcoal} css={[spacer.mrB20]}>
            {props.description}
          </Text>

          {props.name === 'FREE' && (
            <Text color={colors.charcoal} weight='bold' as='strong'>
              No credit card details required.
            </Text>
          )}
        </div>
      </div>
      <div
        css={styles.ctaContainer}
        className='d-flex justify-content-center align-items-center'
      >
        <img
          css={styles.ctaImg}
          src={props.ctaImg}
          alt='pizza slice'
        />
        <Text
          color={colors.black}
          weight='bold'
          size='medium'
          className='text-left'
          css={css`
            flex: 0 1 auto;
            max-width: 100%;
          `}
        >
          {props.ctaText}
        </Text>
      </div>
    </div>
  )
}

PricingCard.propTypes = {
  name: PropTypes.string.isRequired,
  rate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  price: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaImg: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  savings: PropTypes.number.isRequired,
}

export default PricingCard
